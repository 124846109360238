













































import Vue from 'vue';
import api from '@/api';
// types
import { NullableString } from '@/models/Types';
import JobAbstract from '@/models/JobAbstract';
import Location from '@/models/Location'
// components
// import MapLocation from '@/components/MapLocation.vue';

interface Data {
    jobs: JobAbstract[];
    ready: boolean;
    locations: Location[];
}

export default Vue.extend({
    name: "ActiveJobs",
    data(): Data {
        return {
            jobs: [],
            ready: false,
            locations: []
        };
    },
    methods: {
        async loadRequestedCompany() {
            try {
                this.$store.commit('setIsLoading', true);

                let section: NullableString = null;
                const jobrslt = await this.$http.get(`${api}/feed/indeed-jobs`);
                this.jobs = jobrslt.data;

                this.jobs.forEach(j => {
                  if(section != j.industry) {
                    section = j.industry;
                  } else {
                    j.industry = null;
                  }
                });

                // console.info(this.jobs)

                this.ready = true;
            } catch (error) {
                this.ready = false;
                // eslint-disable-next-line
                console.error(error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }
        }
    },
    mounted() {
        this.loadRequestedCompany();
        this.$emit("isExternal");
    },
    destroyed() {
        this.$emit("onLeaving");
    }
});
