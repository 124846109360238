












































import { UUID, isUUID } from '@/models/Types';
import Vue from 'vue';
import api from '@/api';
import CompanyAbstract from '@/models/CompanyAbstract';
import JobAbstract from '@/models/JobAbstract';
// components
import MapLocation from '@/components/MapLocation.vue';

interface Data {
    company: CompanyAbstract | null;
    jobs: JobAbstract[];
    ready: boolean;
}

export default Vue.extend({
    name: "CompanyCareers",
    components: {
        MapLocation
    },
    data(): Data {
        return {
            company: null,
            jobs: [],
            ready: false
        };
    },
    computed: {
        requestedCompanyId(): UUID | undefined {
            return this.$route.params.pid as UUID | undefined;
        }
    },
    watch: {
        requestedCompanyId(id?: UUID) {
            this.resetData();
            if (isUUID(id)) {
                this.loadRequestedCompany(id);
            }
        }
    },
    methods: {
        resetData(): void {
            this.company = null;
            this.jobs = [];
        },
        async loadRequestedCompany(pid: UUID) {
            try {
                this.$store.commit('setIsLoading', true);
                this.resetData();

                const rslt = await this.$http.get(`${api}/profiles/${pid}/careers`);
                this.company = rslt.data;

                const jobrslt = await this.$http.get(`${api}/profiles/${pid}/career-jobs`);
                this.jobs = jobrslt.data;

                this.ready = true;
            } catch (error) {
                this.ready = false;
                // eslint-disable-next-line
                console.error(error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }
        }
    },
    mounted() {
        this.resetData();
        const id = this.requestedCompanyId;
        if (isUUID(id)) {
            this.loadRequestedCompany(id);
        }
        this.$emit("isExternal");
    },
    destroyed() {
        this.$emit("onLeaving");
    }
});
