
























































































































import { UUID, isUUID } from '@/models/Types';
import Vue from 'vue';
import api from '@/api';
import CompanyAbstract from '@/models/CompanyAbstract';
import JobAbstract from '@/models/JobAbstract';
import UserSession from '@/models/UserSession';
import { EffectivePrivileges } from '@/models/Privileges';
import { ProfilePermissions } from '@/models/Permissions';

// components
import ClientInviteModal from '@/components/modals/ClientInviteModal.vue';
import MapLocation from '@/components/MapLocation.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

interface SessionVM extends UserSession {
  payload: CompanyAbstract;
}

interface Job extends JobAbstract {
  age: string;
  candidateCount: number;
}

interface Data {
  ready: boolean;
  parentName: string | null;
  org: CompanyAbstract | null;
  clients: CompanyAbstract[];
  jobs: Job[];
}

export default Vue.extend({
  name: 'ManageProfile',
  components: {
    ClientInviteModal,
    MapLocation,
    BreadCrumb,
  },
  data(): Data {
    return {
      ready: false,
      parentName: null,
      org: null,
      clients: [],
      jobs: [],
    };
  },
  computed: {
    currentRoute(): UUID {
      return this.$route.params.pid as UUID;
    },
    employerSize(): string {
      const size = parseInt(this.org!.employerSize);

      switch (size) {
        case 19:
          return '1 - 19';
        case 49:
          return '20 - 49';
        case 99:
          return '50 - 99';
        case 199:
          return '100 - 199';
        case 200:
          return '200+';
        default:
          return 'not specified';
      }
    },
    inviteDisabled(): boolean {
      return (this.privileges.p & ProfilePermissions.InviteTeammate) == 0;
    },
    editEnabled(): boolean {
      return ((this.privileges.p & ProfilePermissions.Edit) == ProfilePermissions.Edit || (this.privileges.p & ProfilePermissions.ManagePayments) == ProfilePermissions.ManagePayments);
    },
    privileges(): EffectivePrivileges {
      return this.$store.getters.privileges;
    },
  },
  watch: {
    currentRoute(pid: UUID) {
      this.loadProfile(pid);
    },
  },
  methods: {
    resetData(): void {
      this.parentName = null;
      this.org = null;
      this.clients = [];
      this.jobs = [];
    },
    async loadProfile(id: UUID) {
      try {
        this.$store.commit('setIsLoading', true);
        this.resetData();
        const rslt = await this.$http.get(`${api}/profiles/${id}`);
        const rspn: SessionVM = rslt.data;

        this.org = rspn.payload;

        const session: UserSession = {
          token: rspn.token,
          primaryOrg: rspn.primaryOrg,
          currentOrg: rspn.currentOrg,
          currentPrivileges: rspn.currentPrivileges,
        };

        this.$store.commit('setUserSession', session);

        if (isUUID(this.org!.parentId)) {
          try {
            const parent = await this.$http.get(
              `${api}/profiles/${this.org!.parentId}`
            );
            this.parentName = parent.data.companyName;
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        }

        const crqst = await this.$http.get(`${api}/profiles/${id}/clients`);
        this.clients = crqst.data;

        const jobrqst = await this.$http.get(`${api}/profiles/${id}/active-jobs`);
        
        const now = new Date();
        this.jobs = jobrqst.data;

        this.jobs = this.jobs.map((job) => {
          if (job.listDate != null) {
            const day = 1000 * 60 * 60 * 24;
            // const week = day * 7;
            const date = new Date(job.listDate!);
            let interval = (now.getTime() - date.getTime()) / day;
            if (interval <= 1) {
              job.age = 'Today';
            } else if (interval < 7) {
              job.age = `${Math.round(interval)} days`;
            } else {
              interval = interval / 7;
              job.age = `${Math.round(interval)} weeks`;
            }
          }

          job.candidateCount = 0;
          job.candidateStats.forEach((s) => (job.candidateCount += s.count));

          return job;
        });

        this.ready = true;
      } catch (error) {
        this.ready = false;
        // eslint-disable-next-line
        console.error(error);
      } finally {
        this.$store.commit('setIsLoading', false);
      }
    },
  },
  mounted() {
    this.resetData();
    const id = this.currentRoute;
    this.loadProfile(id);
  },
});
