
































import Vue from 'vue';
import Location from '@/models/Location';
// components
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet/dist/leaflet.css';

interface MapData {
    markerPositions: any;
    markerIcon: object;
    zoom: number;
    center: any;
    url: string;
    currentZoom: number;
    showParagraph: boolean;
    mapOptions: object;
    showMap: boolean;
}

interface Data {
    mapData: MapData;
}

export default Vue.extend({
    name: 'MapLocation',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon
    },
    props: {
        locations: { type: Array as () => Location[], required: true }
    },
    data(): Data {
        return {
            mapData: {
                markerPositions: [],
                markerIcon: {
                    iconUrl: require('@/assets/img/pin.svg'),
                    iconSize: [32, 37],
                    iconAnchor: [16, 37]
                },
                zoom: 10,
                center: latLng(0, 0),
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                currentZoom: 11.5,
                showParagraph: false,
                mapOptions: { zoomSnap: 0.5 },
                showMap: false
            }
        }
    },
    mounted() {
        this.locations.forEach((location) => {
            if (location.lat != null && location.long != null) {
                const coord = latLng(location.lat, location.long);
                this.mapData.markerPositions.push(coord);
                if(location.hq) {
                    this.mapData.center = coord;
                }
                this.mapData.showMap = true;
            }
        });
    }
});
